@keyframes slide-in-up {
  0% {
    transform: translateY(100%);
  }
}

:root {
  --slide-in-up: slide-in-up;
  --ease: cubic-bezier(0.25, 0, 0.3, 1);
}
