@use '@/styles/_utils.scss' as *;

$table_padding: getSpacing(2);

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  text-align: left;
}

th {
  @include text--label('small');
  color: var(--color-grey-400);
  padding: getSpacing(1) 0;
}

td {
  padding: $table_padding 0;
}

th,
td {
  &:first-child {
    padding-left: $table_padding;
  }

  &:last-child {
    text-align: right;
    padding-right: $table_padding;
  }
}

tbody td:first-child {
  border-left: $border--default;
}

tbody td:last-child {
  border-right: $border--default;
}

tbody tr {
  background: var(--color-white);

  /* stylelint-disable-next-line no-descending-specificity */
  td {
    position: relative;
    border-bottom: $border--default;
  }

  &:not(:last-child) td {
    &:first-child::before,
    &:last-child::after {
      content: '';
      position: absolute;
      bottom: -1px;
      width: $table_padding;
      height: 1px;
      background-color: var(--color-white);
    }

    &:first-child::before {
      left: 0;
    }

    &:last-child::after {
      right: 0;
    }
  }

  &:first-child td {
    border-top: $border--default;

    &:first-child {
      border-top-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:last-child td {
    &:first-child {
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}
