@use '@/styles/_utils.scss' as *;

// ------------------------------------------------------------
//  SMALL
// ------------------------------------------------------------

.wrapper {
  position: fixed;
  z-index: getZIndex('modal');
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: getSpacing(1);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
}

.container {
  background: var(--color-white);
  padding: getSpacing(5);
  border-radius: $border-radius--x-large;
  border: $border--default;
  width: 100%;
  overflow: auto;
  max-width: px2rem(720);
  margin: auto;
}

.title {
  @include text--heading--primary('2');
  margin-bottom: getSpacing(2);
}

.description {
  color: var(--color-grey-500);
  margin-bottom: getSpacing(2);
}

.innerContainer {
  @include grid--modal();
}
