@use './_utils.scss' as *;
@use './colors';
@use './fonts.scss';
@use './forms.scss';
@use './animations';
@use './globals/table.scss';
@use './storybook.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
button {
  color: var(--color-black);
  font-family: 'Sk-Modernist', sans-serif;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  padding: 0;
  margin: 0;
  @include text--description('small');
}

img {
  display: block;
  max-width: 100%;
}

a {
  color: inherit;
  @include text--link('medium');
}

h1 {
  @include text--heading--primary('1');
  margin: 0;
}

h2 {
  @include text--heading--primary('2');
  margin: 0;
}

h3 {
  @include text--heading--secondary('2', 'bold');
  margin: 0;
}
