@use './_utils.scss' as *;

@each $size in ('1', '2') {
  .typography__h#{$size} {
    @include text--heading--primary($size);
  }
}

@each $size in ('1', '2') {
  .typography__h#{$size} {
    @include text--heading--secondary($size);
  }
}

@each $size in ('medium', 'small', 'x-small') {
  .typography__description-#{$size} {
    @include text--description($size);
  }

  .typography__description-#{$size}-bold {
    @include text--description($size, 'bold');
  }
}

@each $size in ('medium', 'small', 'button') {
  .typography__label-#{$size} {
    @include text--label($size);
  }
}

@each $size in ('medium', 'small') {
  .typography__link-#{$size} {
    @include text--link($size);
  }
}
