:root {
  --color-black: #171717;
  --color-white: #FFF;
  --color-grey-800: #33322C;
  --color-grey-500: #868686;
  --color-grey-400: #B8B8B8;
  --color-grey-300: #DCDCDC;
  --color-grey-200: #F1F1F1;
  --color-grey-100: #F9F9F9;
  --color-green: #6FCF97;
  --color-green-light: #DAF0E3;
  --color-green-dark: #52B77C;
  --color-green-darker: #043623;
  --color-yellow: #FFF3C7;
  --color-yellow-light: #FFFBF4;
  --color-yellow-dark: #FFD090;
  --color-orange: #F2B860;
  --color-orange-light: #FBEBD3;
  --color-orange-dark: #EA9A22;
  --color-red: #F39191;
  --color-red-light: #FBD8D3;
  --color-red-dark: #F56A6A;
}
