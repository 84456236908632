
@font-face {
  font-family: 'Branch';
  src: url('/fonts/Branch.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sk-Modernist';
  font-weight: 700;
  font-style: bold;
  font-display: swap;
  src: url('/fonts/Sk-Modernist-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Sk-Modernist';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/Sk-Modernist-Regular.otf') format('opentype');
}
