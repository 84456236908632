@use '@/styles/_utils' as *;

.modal {
  z-index: 100;
}

.row {
  display: flex;
  grid-column: 1 / -1;
}

.button {
  margin-left: auto;
}

.message {
  display: block;

  &,
  & a {
    @include text--description('small');
  }
}
