@function px2rem($px) {
  @return $px / 16 * 1rem;
}

@function getSpacing($size) {
  @return px2rem($size * 8);
}

$shadows: (
  'medium': -2px 2px 8px rgba(58, 58, 58, 0.05),
  'soft': -2px 2px 4px rgba(58, 58, 58, 0.02),
);

@function getShadow($key) {
  @return map-get($shadows, $key);
}

$zIndexes: (
  'modal': 999,
);

@function getZIndex($key) {
  @return map-get($zIndexes, $key);
}

$border-radius: 2px;
$border-radius--small: 4px;
$border-radius--large: 8px;
$border-radius--x-large: 20px;
$border-radius--rounded: 100px;

$border--default: 1px solid var(--color-grey-200);
$border--active: 1px solid var(--color-black);
$border--error: 1px solid var(--color-red);

$outline--focus: 2px solid var(--color-orange);

// ------------------------------------------------------------
//  MEDIA QUERIES
// ------------------------------------------------------------

@mixin media($size) {
  @if ($size == 'm') {
    @media (min-width: 768px) {
      @content;
    }
  } @else if ($size == 'l') {
    @media (min-width: 1280px) {
      @content;
    }
  } @else {
    @error '$size is invalid';
  }
}

// ------------------------------------------------------------
//  TEXT STYLES
// ------------------------------------------------------------

@mixin text--heading--primary($size) {
  font-family: 'Branch', serif;
  font-weight: 400;

  @if ($size == '1') {
    font-size: px2rem(40);
    line-height: px2rem(40);
  } @else if ($size == '2') {
    font-size: px2rem(32);
    line-height: px2rem(32);
  } @else {
    @error '$size is invalid';
  }
}

@mixin text--heading--secondary($size, $weight: '') {
  font-family: 'Sk-Modernist', sans-serif;

  @if ($weight == 'bold') {
    font-weight: 700;
  } @else {
    font-weight: 400;
  }

  @if ($size == '1') {
    font-size: px2rem(24);
    line-height: px2rem(32);
  } @else if ($size == '2') {
    font-size: px2rem(16);
    line-height: px2rem(24);
  } @else {
    @error '$size is invalid';
  }
}

@mixin text--description($size, $weight: '') {
  font-family: 'Sk-Modernist', sans-serif;

  @if ($weight == 'bold') {
    font-weight: 700;
  } @else {
    font-weight: 400;
  }

  @if ($size == 'medium') {
    font-size: px2rem(16);
    line-height: px2rem(24);
  } @else if ($size == 'small') {
    font-size: px2rem(12);
    line-height: px2rem(16);
  } @else if ($size == 'x-small') {
    font-size: px2rem(10);
    line-height: px2rem(16);
  } @else {
    @error '$size is invalid';
  }
}

@mixin text--label($size) {
  font-family: 'Sk-Modernist', sans-serif;
  @if ($size == 'button') {
    font-weight: 700;
  } @else {
    font-weight: 500;
  }

  @if ($size == 'medium') {
    font-size: px2rem(16);
    line-height: px2rem(24);
  } @else if ($size == 'small') {
    font-size: px2rem(12);
    line-height: px2rem(16);
  } @else if ($size == 'button') {
    font-size: px2rem(14);
    line-height: px2rem(16);
  } @else {
    @error '$size is invalid';
  }
}

@mixin text--link($size) {
  text-decoration: underline;
  font-weight: 400;

  @if ($size == 'medium') {
    font-size: px2rem(16);
    line-height: px2rem(24);
  } @else if ($size == 'small') {
    font-size: px2rem(12);
    line-height: px2rem(16);
  } @else if ($size == 'x-small') {
    font-size: px2rem(10);
    line-height: px2rem(16);
  } @else {
    @error '$size is invalid';
  }
}

// ------------------------------------------------------------
//  GRID
// ------------------------------------------------------------

$page__max-width: px2rem(1920);

$page__padding--xs: getSpacing(3);
$page__padding--md: getSpacing(4);
$page__padding--lg: getSpacing(14);

$grid__gap--xs: getSpacing(2);
$grid__gap--md: getSpacing(3);
$grid__gap--lg: $grid__gap--md;

@mixin grid--xs {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0 $grid__gap--xs;
  padding: 0 $page__padding--xs;
  margin: 0 auto;
}

@mixin grid--md {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0 $grid__gap--md;
  padding: 0 $page__padding--md;
  margin: 0 auto;
}

@mixin grid--lg {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 0 $grid__gap--lg;
  padding: 0 $page__padding--lg;
  margin: 0 auto;
  width: 100%;
  max-width: $page__max-width;
}

@mixin grid--modal() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: getSpacing(2);
}

@mixin grid--aside() {
  display: grid;
  gap: getSpacing(2);
  grid-template-columns: repeat(6, 1fr);
}

@mixin grid--questions() {
  display: grid;
  gap: getSpacing(2);
  grid-template-columns: repeat(12, 1fr);
}
