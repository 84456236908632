@use '@/styles/_utils.scss' as *;

.outer {
  display: inline-block;
  padding: 0;
  margin: 0;
  border-radius: $border-radius--rounded;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  @include text--label('button');

  &:hover,
  &:active {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: getSpacing(1.5) getSpacing(3);
  outline: none;

  .outer:focus:not(:focus-visible) > & {
    outline: $outline--focus;
  }
}

.icon {
  margin-right: getSpacing(1);

  &.isSingle {
    margin-right: 0;
  }
}

.primary,
.secondary,
.danger {
  border: none;
  transition: all 0.2s ease;
}

.primary {
  color: var(--color-white);
  background-color: var(--color-black);

  &:hover {
    color: var(--color-black);
    background-color: var(--color-yellow);
  }

  &:active {
    color: var(--color-white);
    background-color: var(--color-black);
  }

  &:disabled {
    color: var(--color-grey-300);
    background-color: var(--color-yellow-light);
  }
}

.secondary {
  color: var(--color-black);
  background-color: var(--color-yellow-light);

  &:hover {
    color: var(--color-black);
    background-color: var(--color-yellow);
  }

  &:active {
    color: var(--color-black);
    background-color: var(--color-yellow-light);
  }

  &:disabled {
    color: var(--color-grey-300);
    background-color: var(--color-yellow-light);
  }
}

.danger {
  color: var(--color-white);
  background-color: var(--color-red-dark);

  &:hover {
    background-color: var(--color-red);
  }

  &:active {
    background-color: var(--color-red);
  }

  &:disabled {
    color: var(--color-white);
    background-color: var(--color-red-light);
  }
}

.tertiary {
  @include text--label('small');
  background: none;
  border: none;
  width: fit-content;
  width: -moz-fit-content;

  &.inner {
    padding: getSpacing(1);
    padding-right: getSpacing(2);
  }

  &:disabled {
    color: var(--color-grey-400);
    fill: var(--color-grey-400);
  }
}
