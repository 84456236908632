@use './_utils.scss' as *;

%text {
  @include text--description('small');
}

$color: var(--color-black);
$color__placeholder: var(--color-grey-300);

$background: var(--color-white);

$height: getSpacing(5);
$padding-x: getSpacing(1.5);

label {
  display: block;
  padding: 0 0 getSpacing(1);
  margin: 0;

  @include text--label('small');
}

input {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  height: 100%;

  &::placeholder {
    color: $color__placeholder;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &[type='password']::-ms-reveal {
    display: none;
  }
}

.form__hint {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: var(--color-grey-400);
  fill: var(--color-grey-400);
}

.form__hint:first-of-type {
  margin-top: getSpacing(1);
}

.form__hint--valid {
  color: var(--color-green);
  fill: var(--color-green);
}

.form__hint-icon {
  display: block;
  margin-right: getSpacing(1);
}

.form__hint-text {
  display: block;
}

.form__input-group {
  @extend %text;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0;
  height: $height;

  background: $background;
  border: $border--default;
  border-radius: $border-radius--large;

  color: $color;

  &:focus-within {
    border: $border--active;
    outline: none;
  }
}

.form__input-group--error {
  border: $border--error;
}

.form__input-group input {
  border-radius: $border-radius--large;

  &:first-child {
    padding-left: $padding-x;
  }

  &:last-child {
    padding-right: $padding-x;
  }
}

.form__password-reveal {
  height: 100%;
  padding: 0;
  border: none;
  background: none;
  margin: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.form__password-reveal-icon {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 $padding-x;
}

.form__password-reveal[aria-pressed='true'] {
  fill: $color__placeholder;
}

.form__password-reveal:focus,
.form__password-reveal-icon:focus {
  outline: none;
}

.form__password-reveal:focus > .form__password-reveal-icon {
  outline: $outline--focus;
}

.form__submit-btn,
.form__submit-label {
  outline: none;
}

.form__submit-label {
  display: block;
  padding: getSpacing(1.5) getSpacing(3);
}

.form__submit-btn:focus > .form__submit-label {
  outline: $outline--focus;
}

button[type='submit'].form__submit-btn {
  height: auto;
  grid-column: 1 / -1;

  display: block;
  padding: 0;

  border-style: solid;
  border-width: 2px;
  border-radius: $border-radius--large;
  box-shadow: getShadow('soft');

  color: var(--color-white);
  background-color: var(--color-green);
  border-color: var(--color-green-light);

  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include text--label('button');

  &:hover,
  &:focus {
    box-shadow: none;
  }

  &:hover,
  &:active {
    background-color: var(--color-green-dark);
  }

  &.disabled {
    background-color: var(--color-green-light);
  }
}

.form__error {
  @extend %text;
  color: var(--color-red);
  margin: 0;
  padding: getSpacing(0.5) getSpacing(1);
}

.react-select {
  @extend %text;

  /* stylelint-disable-next-line no-descending-specificity */
  input {
    @extend %text;
  }

  .react-select__control {
    height: $height;
    padding: 0 $padding-x;

    background: $background;
    border: $border--default;
    border-radius: $border-radius--large;

    color: $color;

    box-shadow: none;

    &.react-select__control--menu-is-open,
    &.react-select__control--is-focused,
    &:hover {
      border: $border--active;
    }
  }

  .react-select__control--menu-is-open,
  .react-select__control--menu-is-focused {
    border: $border--active;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__placeholder {
    color: $color__placeholder;
  }

  .react-select__indicator {
    padding: 0;
  }

  .react-select__menu {
    background: $background;
    border: $border--active;
    border-radius: $border-radius--large;
    box-shadow: getShadow('medium');
  }

  .react-select__menu-list {
    padding: 0;
    border-radius: $border-radius--large;
  }

  .react-select__option {
    @extend %text;
    display: flex;
    align-items: center;
    padding: 0 $padding-x;
    height: $height;
    cursor: pointer;
    color: var(--color-grey-400);

    &:hover {
      background-color: var(--color-grey-100);
    }

    &:focus {
      border: 2px solid var(--color-orange);
      border-radius: 0;
      outline: none;
    }
  }

  .react-select__option--is-selected {
    color: $color;
    background-color: var(--color-grey-100);
  }

  .react-select__option--is-focused {
    color: inherit;
    background-color: var(--color-grey-200);
  }
}
